@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-y: scroll;
}

/* 以下は印刷画面用のスタイル */
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin-top: 5mm;
  margin-bottom: 12mm;
}